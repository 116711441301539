<template>
  <base-users>
    <template v-slot:default>
      <v-card class="card-user mx-0 my-4">
        <form class="card-user_form">
          <div class="card-user_block" v-for="(item, index) in staffRoleList" :key="item.id">
            <div class="mx-5">
              <v-form ref="form"  v-model="valid" lazy-validation>
              <p class="card-user_txt mb-0">{{ $t("users.userPermission") }}</p>
                <v-text-field
                :rules="ruleName"
                v-model="item.name"
                :disabled="!checkPerUser"
                class="card-user_form_input mt-0 pt-0">
              </v-text-field>
            </v-form>
            </div>
            <div class="d-flex">
              <span class="fs-14 card-user_form_title text-right">{{ $t("users.viewOnly") }}</span>
              <div class="card-user_form_block" >
                <v-textarea
                  class="card-user_form_textarea"
                  outlined
                  :disabled="!checkPerUser"
                  v-model="arrReadPerMiss[index]"
                >
                </v-textarea>
              </div>
            </div>
            <div class="d-flex">
              <span class="fs-14 card-user_form_title text-right">{{ $t("users.modified") }}</span>
              <div class="card-user_form_block">
                <v-textarea
                  class="card-user_form_textarea"
                  outlined
                  :disabled="!checkPerUser"
                  v-model="arrWritePerMiss[index]"
                ></v-textarea>
                <div class="d-flex" v-if="checkPerUser">
                  <v-btn @click="handleSubmit(item.id, index, item.name)"
                  class="card-user_form_btn small mt-0 mb-4"
                  >{{ $t("buttons.save") }}</v-btn
                >
                </div>
              </div>
            </div>
          </div>
          <div class="text-right" v-if="checkPerUser">
            <v-btn
              class="card-user_form_btn btn-create" @click="createItem()"
              >+ {{ $t("buttons.create") }}</v-btn
            >
          </div>
        </form>
      </v-card>
    </template>
  </base-users>
</template>

<script>
import BaseUsers from '../../../../components/Users/BaseUsers'
import gql from 'graphql-tag'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { STAFF_ROLE_LIST } from '@/api/graphql/staff/staff-role'
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  name: 'UserRights',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      id: null,
      valid: true,
      listId: [],
      staffRoleList: [],
      arrReadPerMiss: [],
      arrWritePerMiss: [],
      staffRolePermissionList: [],
      updateArrRead: [],
      updateArrWrite: [],
      newArrRead: [],
      newArrWrite: [],
      ruleName: [v => !!v || this.$t('rules.isRequired'),
        v => v?.length < 256 || this.$t('rules.isInvalid')
      ]
    }
  },
  created () {
    this.getListData()
    this.getListPerrMission()
  },
  methods: {
    ...mapActions({
      actionCreate: 'createStaffRole',
      actionUpdate: 'updateStaffRole'
    }),
    ...mapMutations({
      setStaffRole: 'setStaffRole'
    }),
    async getListData () {
      await this.$apollo.query({
        query: gql`${STAFF_ROLE_LIST}`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.staffRoleList = data.data.staffRoleList
        this.eventStaffRoleList()
      }).catch((error) => {
        console.error(error)
      })
    },
    async getListPerrMission () {
      await this.$apollo.query({
        query: gql`query {
          staffRolePermissionList {
            id key
          }
        }`
      }).then((data) => {
        this.staffRolePermissionList = data.data.staffRolePermissionList
      }).catch((error) => {
        console.error(error)
      })
    },
    eventStaffRoleList () {
      for (let i = 0; i < this.staffRoleList.length; i++) {
        this.arrReadPerMiss.push(this.staffRoleList[i].readPermissions)
        this.arrWritePerMiss.push(this.staffRoleList[i].writePermissions)
      }
      this.arrReadPerMiss = this.arrReadPerMiss.map(item => (item.map(el => el.id)))
      this.arrWritePerMiss = this.arrWritePerMiss.map(item => (item.map(el => el.id)))
    },
    createItem () {
      const data = {
        name: '',
        id: null,
        readPermissions: [],
        writePermissions: []
      }
      this.staffRoleList.push(data)
    },
    handleSubmit (id, index, name) {
      if (this.$refs.form[index].validate()) {
        if (id != null || this.getStaffRole.id != null) {
          this.updateArrRead = []
          this.updateArrWrite = []

          if (this.arrReadPerMiss[index] instanceof Array) {
            this.updateArrRead = this.arrReadPerMiss[index]
          } else {
            const changeToArr = this.arrReadPerMiss[index].split(',')
            for (let i = 0; i < changeToArr.length; i++) {
              if (this.updateArrRead.indexOf(changeToArr[i]) === -1) {
                this.updateArrRead.push(changeToArr[i])
              }
            }
          }

          if (this.arrWritePerMiss[index] instanceof Array) {
            this.updateArrWrite = this.arrWritePerMiss[index].slice()
          } else {
            const changeToArr = this.arrWritePerMiss[index].split(',')
            for (let i = 0; i < changeToArr.length; i++) {
              if (this.updateArrWrite.indexOf(changeToArr[i]) === -1) {
                this.updateArrWrite.push(changeToArr[i])
              }
            }
          }

          const readPermissionIds = []
          const writePermissionIds = []
          const writePermissionKeys = []
          const readPermissionKeys = []
          for (let i = 0; i < this.updateArrRead.length; i++) {
            for (let j = 0; j < this.staffRolePermissionList.length; j++) {
              if (parseInt(this.staffRolePermissionList[j].id) === parseInt(this.updateArrRead[i])) {
                readPermissionIds.push(this.updateArrRead[i])
              }
            }
          }
          for (let i = 0; i < this.updateArrWrite.length; i++) {
            for (let j = 0; j < this.staffRolePermissionList.length; j++) {
              if (parseInt(this.staffRolePermissionList[j].id) === parseInt(this.updateArrWrite[i])) {
                writePermissionIds.push(this.updateArrWrite[i])
              }
            }
          }
          for (let i = 0; i < readPermissionIds.length; i++) {
            for (let j = 0; j < this.staffRolePermissionList.length; j++) {
              if (parseInt(this.staffRolePermissionList[j].id) === parseInt(readPermissionIds[i])) {
                readPermissionKeys.push(this.staffRolePermissionList[j].key)
              }
            }
          }
          for (let i = 0; i < writePermissionIds.length; i++) {
            for (let j = 0; j < this.staffRolePermissionList.length; j++) {
              if (parseInt(this.staffRolePermissionList[j].id) === parseInt(writePermissionIds[i])) {
                writePermissionKeys.push(this.staffRolePermissionList[j].key)
              }
            }
          }
          if (id != null) {
            const staffRole = {
              id: id,
              name,
              readPermissionKeys: readPermissionKeys,
              writePermissionKeys: writePermissionKeys
            }
            this.setStaffRole({ staffRole: staffRole })
          } else {
            const staffRole = {
              id: this.getStaffRole.id,
              name,
              readPermissionKeys: readPermissionKeys,
              writePermissionKeys: writePermissionKeys
            }
            this.setStaffRole({ staffRole: staffRole })
          }
          this.actionUpdate()
        } else {
          const staffRole = {
            name
          }
          this.setStaffRole({ staffRole: staffRole })
          this.actionCreate()
        }
      }
    }
  },
  computed: {
    ...mapGetters({ getStaffRole: 'staffRole' })
  },
  components: {
    BaseUsers
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .card-user {
    width: 90%;
    &_form {
      width: 100%;
      padding: 20px 0;
      color: #000;
      .v-text-field__slot {
        input, textarea {
          color:#000;
        }
      }
    }
  }
}
  .card-user_txt {
    font-size: 10px !important;
    color: #000000
  }
  .card-user_form_title {
    color: #000000 !important
  }
  .card-user_form_btn {
    color: #ffffff !important;
    background-color: #1873D9 !important;
    &.small {
      width: auto !important;
      min-width: auto !important;
      padding: 8px 12px !important;
      font-size: 12px !important;
    }
  }
  .btn-create {
    background-color: #3083DC !important;
    color: #ffffff !important;
    font-size: 16px !important;
    padding: 8px 18px !important;
  }
</style>
